import { environment } from '@env/environment';
import { Buffer } from 'buffer';
import { isEmail } from 'class-validator';

export class StringUtils {
  static base64Decode(encoded: string): string {
    return Buffer.from(encoded, 'base64').toString('binary');
  }

  static base64Encode(text: string): string {
    return Buffer.from(text, 'binary').toString('base64');
  }

  /**
   * The `format` function in TypeScript takes a template string and replaces placeholders with
   * corresponding arguments.
   * @param {string} template - The `template` parameter is a string that contains placeholders in the
   * format `{0}`, `{1}`, `{2}`, and so on, which will be replaced by the corresponding arguments passed
   * to the `formatString` function.
   * @param {string[]} args - The `args` parameter in the `formatString` function is an array of strings
   * that represent the values to be inserted into the template string at the corresponding placeholders.
   * @returns The `format` function takes a template string and an array of arguments, replaces
   * placeholders in the template string with the corresponding arguments, and returns the formatted
   * string.
   */
  static format(template: string, ...args: string[]): string {
    return template.replace(/{([0-9]+)}/g, function (match, index) {
      return typeof args[index] === 'undefined' ? match : args[index];
    });
  }

  // encryption is done by using Web Crypto API
  static async encryptWithPublicKey(data: string) {
    const bufferMessage = Buffer.from(data, 'utf8');
    const publicKey: CryptoKey = await this.importPublicKey();
    const encryptedData = await window.crypto.subtle.encrypt(
      { name: 'RSA-OAEP' },
      publicKey,
      bufferMessage,
    );
    return Buffer.from(encryptedData).toString('base64');
  }

  private static async importPublicKey(): Promise<CryptoKey> {
    const binaryDer: ArrayBuffer = this.pemToArrayBuffer(
      environment.RSA_PUBLIC_KEY,
    );
    return window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
  }

  private static pemToArrayBuffer(pem: string): ArrayBuffer {
    const b64: string = pem.replace(
      /-----BEGIN PUBLIC KEY-----|-----END PUBLIC KEY-----|\s/g,
      '',
    );
    const binaryDerString: string = atob(b64);
    const binaryDer: Uint8Array = new Uint8Array(binaryDerString.length);
    for (let i = 0; i < binaryDerString.length; i++) {
      binaryDer[i] = binaryDerString.charCodeAt(i);
    }
    return binaryDer.buffer;
  }

  static isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === 'undefined' ||
      (typeof value === 'string' && value.trim().length === 0) ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    );
  }

  static getShowDate(date: any) {
    date = new Date(date);
    return date?.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  static capitalizeFirstLetter(string: string): string{
    if (this.isEmpty(string)) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static isValidEmail(email:string): boolean{
    return isEmail(email);
  }
  
  static convertDateToReadableString(date: Date | string): string {
    return new Date(date)
      .toISOString()
      .replace("T", " ")
      .replace(/\.\d{3}Z$/, "");
  }

  static formatToISTDateTime(date?: Date): string {
    // Default to the current date if no date is provided
    const inputDate: Date = date ?? new Date();

    // Options for converting UTC to IST with 24-hour format
    const options: Intl.DateTimeFormatOptions = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // For 24-hour format
    };

    // Convert to IST using toLocaleString with the provided options
    const istDateTime: string = inputDate.toLocaleString("en-IN", options);

    // Split date and time, and reformat the date as YYYY-MM-DD
    const [datePart, timePart] = istDateTime.split(", ");
    const formattedDate: string = datePart.split("/").reverse().join("-"); // Convert to YYYY-MM-DD

    // Return the final formatted date and time in "YYYY-MM-DD HH:mm:ss"
    return `${formattedDate} ${timePart}`;
  }


}
